<template>
  <div class="flex items-center text-sm font-bold">
    <skeleton v-if="waiting" class="h-6 w-full" />
    <div v-else class="flex items-center">
      <HomeIcon
        class="h-5 w-5 cursor-pointer"
        :style="{
          color: hovers[0]
            ? company.primary[600]
            : !items.length
            ? company.primary[500]
            : null,
        }"
        @click="router.push('/')"
        @mouseover="hovers[0] = true"
        @mouseleave="hovers[0] = false"
      />
      <div v-for="(item, index) in items" :key="item" class="flex items-center">
        <ChevronRightIcon class="mx-2 h-3 w-3" />
        <span
          class="cursor-pointer"
          :style="{
            color: hovers[index + 1]
              ? company.primary[600]
              : index == items.length - 1
              ? company.primary[500]
              : null,
          }"
          @click="router.push(item.to)"
          @mouseover="hovers[index + 1] = true"
          @mouseleave="hovers[index + 1] = false"
          >{{
            item.translatable ? t(`breadcrumb.${item.label}`) : item.label
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Skeleton from "../loading/Skeleton.vue";
export default {
  components: {
    ChevronRightIcon,
    HomeIcon,
    Skeleton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const hovers = ref([]);

    const items = ref([]);

    const bulletin = computed(() => store.state.bulletin.bulletin);
    const company = computed(() => store.state.company.company);
    const document = computed(() => store.state.document.document);
    const file = computed(() => store.state.file.file);
    const notification = computed(() => store.state.notification.notification);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.document.waiting) return true;
      if (store.state.file.waiting) return true;
      if (store.state.notification.waiting) return true;
      return false;
    });

    const setBreadcrumbs = () => {
      items.value = [];
      let label = "";
      let to = "";
      for (const item of route.meta.breadcrumb) {
        let translatable = false;
        if (item == "bulletin") {
          label = bulletin.value.topic;
          to += `/${bulletin.value.id}`;
        } else if (item == "document") {
          label = document.value.name;
          to += `/${document.value.id}`;
        } else if (item == "file") {
          label = file.value.name;
          to += `/${file.value.id}`;
        } else if (item == "notification") {
          label = notification.value.name;
          to += `/${notification.value.id}`;
        } else {
          label = item;
          to += `/${item}`;
          translatable = true;
        }
        items.value.push({
          label,
          to,
          translatable,
        });
      }
    };

    onMounted(() => setBreadcrumbs());

    watch(
      () => bulletin.value,
      (bulletin) => {
        if (bulletin.id) {
          setBreadcrumbs();
        }
      }
    );

    watch(
      () => document.value,
      (document) => {
        if (document.id) {
          setBreadcrumbs();
        }
      }
    );

    return {
      company,
      hovers,
      items,
      router,
      t,
      waiting,
    };
  },
};
</script>
