const filters = {
  search: (items, searchTerm, searchFields) => {
    return items.filter((item) => {
      return Object.entries(item).some(
        ([key, val]) =>
          searchFields.includes(key) &&
          val.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  },
};

const filter = (items, searchTerm, searchFields, rules = []) => {
  if (searchTerm.length) {
    return filters.search(items, searchTerm, searchFields);
  }
  return items;
};

export default filter;
